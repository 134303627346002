import React from 'react';
import { Skeleton } from 'antd';

const DashSkeleton: React.FC = () => {
  return (
    <>
      <Skeleton paragraph={{ rows: 1 }} />
      <Skeleton avatar paragraph={{ rows: 2 }} />{' '}
    </>
  );
};

export default DashSkeleton;
