import React from 'react';
import { Statistic } from 'antd';
import _ from 'lodash';
import { PlainObject } from '../../../../types/common';
import { StatisticWidgetConfig } from '../../types/statistic';
import { statisticWidgetIconMap } from '../../helper';

interface StatisticWidgetProps {
  data: PlainObject[];
  config: StatisticWidgetConfig;
}

const StatisticWidget: React.FC<StatisticWidgetProps> = ({
  data = [],
  config,
}) => {
  const getValueStyle = () => {
    if (_.isEmpty(data[0]?.valuestyle)) {
      return config;
    }

    const resultValueStyle: object = _.isEmpty(data)
      ? config.valueStyle
      : JSON.parse(data[0]?.valuestyle);

    return {
      ...config,
      valueStyle: resultValueStyle,
    };
  };

  const resultConfig = {
    ...getValueStyle(),
    ...data[0],
  };

  // @ts-ignore
  const prefix = resultConfig.prefix || '';
  // @ts-ignore
  switch (resultConfig.statisticType) {
    case 'countdown':
      return <Statistic.Countdown {...resultConfig} />;

    default:
      return (
        <Statistic
          {...resultConfig}
          prefix={statisticWidgetIconMap[prefix] || null}
        />
      );
  }
};

export default StatisticWidget;
