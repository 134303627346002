import React from 'react';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { filterByRights } from './menu';
import { getSelectedKeys } from './helper';
import { useSelector } from 'react-redux';
import { selectCurrentUserGrants } from '../../../features/user/userSlice';

const ManagerMenu: React.FC = () => {
  const location = useLocation();
  const currentUserGrants = useSelector(selectCurrentUserGrants);

  const items = filterByRights(currentUserGrants);
  return (
    <Menu selectedKeys={getSelectedKeys(location.pathname, items)}>
      {items.map(item => {
        return (
          <Menu.Item key={item.name} icon={item.icon}>
            <NavLink to={item.link}>{item.label}</NavLink>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default ManagerMenu;
