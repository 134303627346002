import React from 'react';
import { Button } from 'antd';
import { DisplayMode } from '../../features/dashboard/types/displayMode';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

interface Props {
  value: DisplayMode;
  hidden?: boolean;
  onChange: (mode: DisplayMode) => any;
}

const Mode: React.FC<Props> = ({ value, onChange, hidden = false }) => {
  if (hidden) return null;

  const toggleMode = () => {
    if (value === DisplayMode.EDIT) {
      onChange(DisplayMode.VIEW);
    } else {
      onChange(DisplayMode.EDIT);
    }
  };

  const icon = value === DisplayMode.VIEW ? <EditOutlined /> : <EyeOutlined />;

  return <Button shape="circle" icon={icon} onClick={toggleMode} />;
};

export default Mode;
