import React from 'react';
import styles from '../../Widget.module.less';

interface TextWidgetProps {
  data: [{ value?: string }];
}

const TextWidget = ({ data }: TextWidgetProps) => {
  const value = (data && data[0]?.value) || '';
  return <span className={styles.textWidgetStyles}>{value}</span>;
};

export default TextWidget;
