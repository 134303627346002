import React from 'react';
import AddDashboardButton from './AddDashboardButton';
import { Empty } from 'antd';
import { useSelector } from 'react-redux';
import { selectCurrentUserGrants } from '../../../user/userSlice';
import { Permissions } from '../../../settings/types/user';

const description = 'Вы не добавили ни одного дашборда';

const EmptyList = () => {
  const grants = useSelector(selectCurrentUserGrants);
  const addDashPermission = grants.includes(Permissions.DASHBOARD_EDIT);

  return (
    <>
      <Empty description={description}>
        <AddDashboardButton hidden={!addDashPermission} />
      </Empty>
    </>
  );
};

export default EmptyList;
