import { createIntl, createIntlCache } from 'react-intl';
import { store } from '../store';
import { Locale } from '../i18n/locale';
import messages_en from '../i18n/messages/en_US.json';
import messages_ru from '../i18n/messages/ru_RU.json';
import messages_de from '../i18n/messages/de_DE.json';

const cache = createIntlCache();
const { locale } = store.getState().i18n;

const messages = {
  [Locale.ENGLISH]: messages_en,
  [Locale.RUSSIAN]: messages_ru,
  [Locale.DEUTSCH]: messages_de,
};

export const intl = createIntl({ locale, messages: messages[locale] }, cache);
