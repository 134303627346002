import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import ManagerMainLayout from '../../../../components/layout/ManagerMainLayout';
import SettingsUsers from '../SettingsUsers';
import SettingsGroups from '../SettingsGroups';
import { useSelector } from 'react-redux';
import { fetchDashboardList } from '../../../dashboard/dashboardSlice';
import { hasAccess } from '../../../../utils/rights';
import { selectCurrentUserGrants } from '../../../user/userSlice';
import { Permissions } from '../../types/user';
import SettingsData from '../SettingsData';
import { useAppDispatch } from '../../../../store';

const { TabPane } = Tabs;

const USERS_PERMISSIONS = [Permissions.USER_EDIT, Permissions.USER_READ];
const GROUPS_PERMISSIONS = [Permissions.GROUP_EDIT, Permissions.GROUP_READ];
const DATA_PERMISSIONS = [Permissions.DATA_LOAD];

const Settings: React.FC = () => {
  const userGrants = useSelector(selectCurrentUserGrants);
  const hasUsersTabAccess = hasAccess(USERS_PERMISSIONS, userGrants);
  const hasGroupsTabAccess = hasAccess(GROUPS_PERMISSIONS, userGrants);
  const hasDataTabAccess = hasAccess(DATA_PERMISSIONS, userGrants);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchDashboardList());
  }, [dispatch]);

  const usersTab = (
    <FormattedMessage id="settings.Accounts" defaultMessage="Учетные записи" />
  );

  const groupsTab = (
    <FormattedMessage id="settings.Groups" defaultMessage="Группы" />
  );

  const dataTab = (
    <FormattedMessage id="settings.Data" defaultMessage="Работа с данными" />
  );

  return (
    <ManagerMainLayout>
      <Tabs defaultActiveKey="1">
        {hasUsersTabAccess && (
          <TabPane tab={usersTab} key="users">
            <SettingsUsers />
          </TabPane>
        )}
        {hasGroupsTabAccess && (
          <TabPane tab={groupsTab} key="groups">
            <SettingsGroups />
          </TabPane>
        )}
        {hasDataTabAccess && (
          <TabPane tab={dataTab} key="data">
            <SettingsData />
          </TabPane>
        )}
      </Tabs>
    </ManagerMainLayout>
  );
};

export default Settings;
