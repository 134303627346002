import { uuid } from './index';

const tabIdSessionKey = 'tabId';

export const getTabId = () => sessionStorage.getItem(tabIdSessionKey);

export const createTabId = () => {
  const uniqId = uuid();
  const tabId = getTabId();
  if (!tabId) {
    sessionStorage.setItem(tabIdSessionKey, uniqId);
  }
};
