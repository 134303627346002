import { AppThunk, RootState } from '../../store';
import API from '../../utils/API';
import { setError } from '../error/errorSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Profile, Role, User, UserSettings } from './types/user';
import { Group, GroupListRequest } from './types/group';
import { DataItem } from './types/data';
import { RcFile } from 'antd/es/upload';

interface SettingsState {
  userList: User[];
  userGroupList: Group[];
  roles: Role[];
  group: Group | {};
  loading: boolean;
  userSettings: UserSettings;
  settingsData: DataItem[];
  uploadingId: string;
}

const initialState: SettingsState = {
  userList: [],
  userGroupList: [],
  roles: [],
  group: {},
  loading: false,
  userSettings: {},
  settingsData: [],
  uploadingId: '',
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    loading: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    receiveUserList: (state: SettingsState, action: PayloadAction<User[]>) => {
      state.userList = action.payload;
    },
    receiveGroupList: (
      state: SettingsState,
      action: PayloadAction<Group[]>,
    ) => {
      state.userGroupList = action.payload;
    },
    receiveRoleList: (state: SettingsState, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
    receiveGroup: (state: SettingsState, action: PayloadAction<Group | {}>) => {
      state.group = action.payload;
    },
    receiveUserSettings: (
      state: SettingsState,
      action: PayloadAction<UserSettings | {}>,
    ) => {
      state.userSettings = action.payload;
    },
    receiveSettingsData: (
      state: SettingsState,
      { payload }: PayloadAction<DataItem[]>,
    ) => {
      state.settingsData = payload;
    },
    setUploadingId: (state, { payload }: PayloadAction<string>) => {
      state.uploadingId = payload;
    },
  },
});

export const {
  loading,
  receiveUserList,
  receiveGroupList,
  receiveRoleList,
  receiveGroup,
  receiveUserSettings,
  receiveSettingsData,
  setUploadingId,
} = settingsSlice.actions;

// middlewares

type ProfileWithoutId = Omit<Profile, 'id'>;

export const createUser =
  (
    user: Omit<User<ProfileWithoutId>, 'id'>,
    successCb?: (id: string) => void,
  ): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.post('./api/user/create', user);
      if (typeof successCb === 'function') {
        successCb(data.result.id);
      }
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const updateUser =
  (user: User, successCb?: (id: string) => void): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.put('./api/user', user);
      if (typeof successCb === 'function') {
        successCb(data.result.id);
      }
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const deleteUser =
  (userId: string, successCb?: () => void): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      await API.delete(`./api/user/${userId}`);
      if (typeof successCb === 'function') {
        successCb();
      }
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

/**
 * Fetch all users
 */
export const fetchUserList =
  (payload: object): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      const response = await API.post('/api/user/list', payload);
      dispatch(receiveUserList(response.data.result?.content));
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

/**
 * Fetch all groups
 */
export const fetchGroupList =
  (payload: GroupListRequest): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      const response = await API.post('/api/group/list', payload);
      dispatch(receiveGroupList(response.data.result?.content));
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

export const createGroup =
  (group: Omit<Group, 'id'>, successCb?: (id: string) => void): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.post('./api/group', group);
      if (typeof successCb === 'function') {
        successCb(data.result.id);
      }
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const updateGroup =
  (group: Group, successCb?: (id: string) => void): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.put('./api/group', group);
      if (typeof successCb === 'function') {
        successCb(data.result.id);
      }
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const deleteGroup =
  (groupId: string, successCb?: () => void): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      await API.delete(`./api/group/${groupId}`);
      if (typeof successCb === 'function') {
        successCb();
      }
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

export const fetchRoles = (): AppThunk => async dispatch => {
  try {
    const { data } = await API.get('/api/role');
    dispatch(receiveRoleList(data?.result));
  } catch (e: any) {
    dispatch(setError(e));
  }
};

export const fetchGroup =
  (groupId: string, successCb?: () => void): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.get(`/api/group/${groupId}`);
      dispatch(receiveGroup(data?.result));
      successCb?.();
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const fetchUserSettings =
  (successCb?: () => void): AppThunk =>
  async dispatch => {
    try {
      const { data } = await API.get(`/public/config/user/data/manage`);
      dispatch(receiveUserSettings(data?.result));
      successCb?.();
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const fetchDataSettings = (): AppThunk => async dispatch => {
  try {
    const { data } = await API.get<{ result: DataItem[] }>('/api/dataflow');
    dispatch(receiveSettingsData(data?.result));
  } catch (e: any) {
    dispatch(setError(e));
  }
};

export const uploadDataSettings =
  (dataFlowId: string, file?: RcFile, successCb?: () => void): AppThunk =>
  async dispatch => {
    dispatch(setUploadingId(dataFlowId));
    const formData = new FormData();
    formData.append('file', file || '');
    try {
      await API.post(`/api/dataflow/${dataFlowId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*',
        },
      });
      dispatch(setUploadingId(''));
      successCb?.();
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(setUploadingId(''));
    }
  };

// selectors

export const selectUserList = (state: RootState): User[] =>
  state.settings.userList;
export const selectGroupList = (state: RootState): Group[] =>
  state.settings.userGroupList;
export const selectRoleList = (state: RootState): Role[] =>
  state.settings.roles || [];
export const selectGroup = (state: RootState): Group | {} =>
  state.settings.group || {};
export const selectUserSettings = (state: RootState): UserSettings =>
  state.settings.userSettings || {};
export const selectSettingsData = (state: RootState): DataItem[] =>
  state.settings.settingsData;
export const selectUploadingId = (state: RootState): string =>
  state.settings.uploadingId;

export default settingsSlice.reducer;
