import React from 'react';
import {
  dateOfBirthLabel,
  emailLabel,
  fullNameLabel,
  groupsLabel,
  lastEntranceLabel,
  loginLabel,
  phoneNumberLabel,
  roleLabel,
} from './AddUser/helper';
import { User } from '../../types/user';
import moment from 'moment';
import styles from './styles.module.less';
import { sortAlphabetically } from '../../../../utils';
import { isEmpty } from 'lodash';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import {
  lastEntranceDateFormat,
  serverDateFormat,
} from '../../../../utils/dateFormats';

const LAST_NAME = 'profile.lastName';
const USERNAME = 'username';
const ROLE = 'roleName';
const EMAIL = 'profile.email';
const PHONE = 'profile.phone';
const BIRTHDAY = 'profile.birthDay';
const GROUP_NAMES = 'profile.groupNames';
const LAST_ENTRANCE = 'lastEntrance';

interface ColumnFilterData {
  [LAST_NAME]: ColumnFilterItem[];
  [USERNAME]: ColumnFilterItem[];
  [ROLE]: ColumnFilterItem[];
  [EMAIL]: ColumnFilterItem[];
  [PHONE]: ColumnFilterItem[];
  [BIRTHDAY]: ColumnFilterItem[];
  [LAST_ENTRANCE]: ColumnFilterItem[];
  [GROUP_NAMES]: ColumnFilterItem[];
}

const sortEmptyFirst = <T extends { value: string }>(a: T): number =>
  !a.value ? -1 : 1;

const getFormattedDate = (
  date: string | null,
  format: string = 'L',
  initialFormat?: string,
) => (date ? moment(date, initialFormat).format(format) : '');

export const getPreparedData = (userList: User[], intl: any) => {
  const emptyLabel = intl.formatMessage({
    id: 'Common.empty',
    defaultMessage: 'Пусто',
  });
  // @ts-ignore
  const getUniqueData = (data: any[]) => [...new Set(data)];
  const groupNamesData: string[] = [];
  userList
    .map(({ profile: { groupNames } }) => {
      return groupNames.length ? groupNames : [''];
    })
    .forEach(item => groupNamesData.push(...item));

  return {
    [LAST_NAME]: getUniqueData(
      userList.map(({ profile: { lastName, firstName, middleName } }) => {
        return `${lastName || ''} ${firstName || ''} ${
          middleName || ''
        }`.trim();
      }),
    )
      .map(item => ({ text: item || emptyLabel, value: item }))
      .sort(sortEmptyFirst),
    [USERNAME]: getUniqueData(userList.map(({ username }) => username))
      .filter(item => !isEmpty(item))
      .map(item => ({ text: item, value: item })),
    [ROLE]: getUniqueData(userList.map(({ roleName }) => roleName))
      .map(item => ({
        text: item || emptyLabel,
        value: item,
      }))
      .sort(sortEmptyFirst),
    [EMAIL]: getUniqueData(userList.map(({ profile: { email } }) => email))
      .filter(item => !isEmpty(item))
      .map(item => ({ text: item || emptyLabel, value: item })),
    [PHONE]: getUniqueData(userList.map(({ profile: { phone } }) => phone))
      .map(item => ({ text: item || emptyLabel, value: item }))
      .sort(sortEmptyFirst),
    [BIRTHDAY]: getUniqueData(
      userList.map(({ profile: { birthDay } }) => getFormattedDate(birthDay)),
    )
      .map(item => ({ text: item || emptyLabel, value: item }))
      .sort(sortEmptyFirst),
    [LAST_ENTRANCE]: getUniqueData(
      userList.map(({ lastEntrance }) =>
        getFormattedDate(
          lastEntrance,
          lastEntranceDateFormat,
          serverDateFormat,
        ),
      ),
    )
      .map(item => ({ text: item || emptyLabel, value: item }))
      .sort(sortEmptyFirst),
    [GROUP_NAMES]: getUniqueData(groupNamesData)
      .map(item => ({
        text: item || emptyLabel,
        value: item,
      }))
      .sort(sortEmptyFirst),
  };
};

const getFormattedGroups = (groups: string[] = []) => {
  return (
    <div className={styles.flexContainer}>
      <span>{groups?.[0]}</span>
      {groups && groups.length > 1 && (
        <span title={groups.join(', ')}>
          (<span>{groups.length > 1 && groups.length}</span>)
        </span>
      )}
    </div>
  );
};

export const getColumns = (allPreparedData: ColumnFilterData): object[] => {
  return [
    {
      title: fullNameLabel,
      dataIndex: ['profile', 'lastName'],
      key: LAST_NAME,
      textWrap: 'word-break',
      ellipsis: true,
      sorter: {
        compare: (a: User, b: User) =>
          sortAlphabetically(a.profile.lastName, b.profile.lastName),
      },
      onFilter: (value: string, record: User) => {
        const fullName = `${record.profile.lastName || ''} ${
          record.profile.firstName || ''
        } ${record.profile.middleName || ''}`.trim();
        return value === fullName;
      },
      filters: allPreparedData[LAST_NAME],
      filterSearch: true,
      filterMode: 'tree',
      render: (text: string, user: User) => {
        return (
          <div className={styles.fullNameContainer}>
            <div>{`${text ? text : ''} ${
              user.profile?.firstName ? user.profile?.firstName : ''
            } ${
              user.profile?.middleName ? user.profile?.middleName : ''
            }`}</div>
          </div>
        );
      },
    },
    {
      title: loginLabel,
      dataIndex: USERNAME,
      key: USERNAME,
      ellipsis: true,
      textWrap: 'word-break',
      sorter: {
        compare: (a: User, b: User) =>
          sortAlphabetically(a.username, b.username),
      },
      onFilter: (value: string, record: User) => value === record[USERNAME],
      filters: allPreparedData[USERNAME],
      filterSearch: true,
      filterMode: 'tree',
    },
    {
      title: roleLabel,
      dataIndex: ROLE,
      ellipsis: true,
      textWrap: 'word-break',
      key: ROLE,
      sorter: (a: User, b: User) => sortAlphabetically(a.roleName, b.roleName),
      onFilter: (value: string, record: User) => value === record[ROLE],
      filters: allPreparedData[ROLE],
      filterSearch: true,
      filterMode: 'tree',
    },
    {
      title: emailLabel,
      dataIndex: ['profile', 'email'],
      ellipsis: true,
      textWrap: 'word-break',
      key: EMAIL,
      sorter: {
        compare: (a: User, b: User) =>
          sortAlphabetically(a.profile?.email, b.profile?.email),
      },
      onFilter: (value: string, record: User) => value === record.profile.email,
      filters: allPreparedData[EMAIL],
      filterSearch: true,
      filterMode: 'tree',
    },
    {
      title: phoneNumberLabel,
      dataIndex: ['profile', 'phone'],
      ellipsis: true,
      textWrap: 'word-break',
      key: PHONE,
      sorter: {
        compare: (a: User, b: User) =>
          sortAlphabetically(a.profile?.phone, b.profile?.phone),
      },
      onFilter: (value: string, record: User) => value === record.profile.phone,
      filters: allPreparedData[PHONE],
      filterSearch: true,
      filterMode: 'tree',
    },
    {
      title: dateOfBirthLabel,
      dataIndex: ['profile', 'birthDay'],
      ellipsis: true,
      textWrap: 'word-break',
      key: BIRTHDAY,
      sorter: {
        compare: (a: User, b: User) =>
          sortAlphabetically(a.profile?.birthDay, b.profile?.birthDay),
      },
      onFilter: (value: string, record: User) => {
        const date = record.profile.birthDay || '';
        return value === getFormattedDate(date);
      },
      filters: allPreparedData[BIRTHDAY],
      filterSearch: true,
      filterMode: 'tree',
      render: (text: string) => (text ? getFormattedDate(text) : ''),
    },
    {
      title: lastEntranceLabel,
      dataIndex: LAST_ENTRANCE,
      ellipsis: true,
      textWrap: 'word-break',
      key: LAST_ENTRANCE,
      sorter: {
        compare: (a: User, b: User) =>
          sortAlphabetically(a?.lastEntrance, b?.lastEntrance),
      },
      onFilter: (value: string, record: User) => {
        const date = record.lastEntrance || '';
        return (
          value ===
          getFormattedDate(date, lastEntranceDateFormat, serverDateFormat)
        );
      },
      filters: allPreparedData[LAST_ENTRANCE],
      filterSearch: true,
      filterMode: 'tree',
      render: (text: string) =>
        text
          ? getFormattedDate(text, lastEntranceDateFormat, serverDateFormat)
          : '',
    },
    {
      title: groupsLabel,
      dataIndex: ['profile', 'groupNames'],
      textWrap: 'word-break',
      key: GROUP_NAMES,
      sorter: {
        compare: (a: User, b: User) =>
          sortAlphabetically(
            a.profile?.groupNames[0],
            b.profile?.groupNames[0],
          ),
      },
      onFilter: (value: string, record: User) =>
        value
          ? record.profile.groupNames.includes(value)
          : !record.profile.groupNames.length,
      filters: allPreparedData[GROUP_NAMES],
      filterSearch: true,
      filterMode: 'tree',
      render: (text: string[]) => {
        return <div>{getFormattedGroups(text)}</div>;
      },
    },
  ];
};
