import React, { useEffect } from 'react';
import ManagerMainLayout from '../../../../components/layout/ManagerMainLayout';
import { useSelector } from 'react-redux';
import {
  fetchDashboardList,
  selectDashboardList,
  reset,
  isDashboardLoading,
} from '../../dashboardSlice';
import DashList from './DashList';
import { useAppDispatch } from '../../../../store';

interface Props {
  basePath: string;
}

const DashboardList: React.FC<Props> = ({ basePath }) => {
  const data = useSelector(selectDashboardList);
  const loading = useSelector(isDashboardLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDashboardList());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <ManagerMainLayout>
      <DashList dashboards={data} loading={loading} basePath={basePath} />
    </ManagerMainLayout>
  );
};

export default DashboardList;
