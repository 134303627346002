import tokenService from './tokenService';
import { updateKcToken } from '../hooks/useKeycloak';
import { createTabId, getTabId } from './tabId';
import {EventSourceMessage, fetchEventSource} from "@microsoft/fetch-event-source";
import {baseURL} from "../config";

export interface SseParams {
    headers?: Record<string, string>;
    ctrl: AbortController;
    onmessage?: (ev: EventSourceMessage) => void;
    onerror?: (err: any) => void;
    onopen?: (res: Response) => Promise<void>;
}

let wasRequest = false;
let stack: Array<() => Promise<void>> = [];

const retry = async () => {
    wasRequest = true;
    await updateKcToken();
    wasRequest = false;
    stack.forEach((el, i) => {
        el();
        stack = stack.slice(0, i);
    });
};

export const fetchSSe = async (url: string, otherParams: SseParams) => {
    createTabId();
    const tabId = getTabId() || '';
    let token = tokenService.getAccessToken();
    const { headers, ctrl, onmessage, onerror, onopen } = otherParams;
    await fetchEventSource(`${baseURL}${url}`, {
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            tabId,
        },
        onopen: async resp => {
            onopen?.(resp);
            if (resp.status === 401) {
                stack.push(() => fetchSSe(url, { ctrl }));
                if (!wasRequest) {
                    await retry();
                }
            }
        },
        onmessage,
        onerror,
        signal: ctrl.signal,
    });
};
