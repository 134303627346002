import { AxiosError } from 'axios';

export const accessProhibited = 'accessProhibited';
const defaultErrorKey = 'defaultError';
const TOKEN_ERROR_CODE = 401;
const USER_ACCESS_ERROR_CODE = 403;

/**
 * Return simple error id for using in redux state
 */
export const getErrorKey = (error: AxiosError): string => {
  if (isUserAccessError(error)) {
    return accessProhibited;
  }

  if (error.isAxiosError) {
    return error.response?.data?.error || defaultErrorKey;
  }

  return defaultErrorKey;
};

export const isUserAccessError = (error: AxiosError): boolean => error?.response?.status === USER_ACCESS_ERROR_CODE;
export const isTokenError = (error: AxiosError): boolean => error.response?.status === TOKEN_ERROR_CODE;
