import React, { useEffect } from 'react';
import { message, Table } from 'antd';
import { DataItem } from '../../types/data';
import { getColumns } from './columns';
import { useSelector } from 'react-redux';
import {
  uploadDataSettings,
  fetchDataSettings,
  selectSettingsData,
  selectUploadingId,
} from '../../settingsSlice';
import { RcFile } from 'antd/es/upload';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '../../../../store';

const SettingsData = () => {
  const dispatch = useAppDispatch();
  const data = useSelector(selectSettingsData);
  const uploadingId = useSelector(selectUploadingId);
  const intl = useIntl();
  const successMessage = intl.formatMessage({
    id: 'app.FileUploaded',
    defaultMessage: 'Файл загружен',
  });

  const uploadSuccess = () => message.success(successMessage);

  const onUploadClick = (id: string, file: RcFile) =>
    dispatch(uploadDataSettings(id, file, uploadSuccess));

  const columns = getColumns(onUploadClick, uploadingId);

  useEffect(() => {
    dispatch(fetchDataSettings());
  }, []);

  return <Table<DataItem> dataSource={data} columns={columns} />;
};

export default SettingsData;
