import _ from 'lodash';
import { PieChartWidgetConfig } from './types';
import { Label } from '@antv/g2plot/lib/types/label';
import { Statistic } from '@antv/g2plot/lib/types/statistic';
import { PlainObject } from '../../../../../../types/common';
import { renderStatistic } from '../../../../helper';

export const getPieLabel = (config: PieChartWidgetConfig): Label => {
  const { numberAfterPoint } = config.label;

  if (_.isEmpty(config.label.content)) {
    return config.label as Label;
  }

  return {
    ...config.label,

    content: ({ percent }) =>
      `${(percent * 100).toFixed(numberAfterPoint || 0)}%`,
  };
};

export const getPieStatistic = (config: PieChartWidgetConfig): Statistic => {
  if (!config.statistic?.title) {
    return config.statistic;
  }

  return {
    ...config.statistic,
    title: {
      offsetY: -4,
      customHtml: (container: Element, view: any, datum: PlainObject) => {
        const { width, height } = container.getBoundingClientRect();
        const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
        const text = datum ? datum.label : 'Итого';
        return renderStatistic(d, text);
      },
    },
    content: {
      ...config.statistic.content,
      offsetY: 4,
      customHtml: (
        container: Element,
        view: any,
        datum: PlainObject,
        htmlData: any[],
      ) => {
        const { width } = container.getBoundingClientRect();
        const sum = htmlData.reduce((r, d) => r + d.value, 0);
        const numberAfterPoint = config.label?.numberAfterPoint;
        let text = '';

        if (datum) {
          if (numberAfterPoint) {
            text = `${((datum.value / sum) * 100).toFixed(numberAfterPoint)}`;
          } else {
            text = datum.value;
          }
        } else {
          text = numberAfterPoint ? '100' : sum;
        }

        return renderStatistic(width, text);
      },
    },
  };
};
