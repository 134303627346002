import React, { memo, useMemo } from 'react';
import { Pie } from '@ant-design/charts';
import { PieChartWidgetConfig } from './types';
import { getPieLabel, getPieStatistic } from './helper';

interface PieChartWidgetProps {
  data: any[];
  config: PieChartWidgetConfig;
}

const PieChartWidget: React.FC<PieChartWidgetProps> = ({ data, config }) => {
  const label = useMemo(() => getPieLabel(config), [config]);
  const statistic = useMemo(() => getPieStatistic(config), [config]);

  return (
    <Pie
      {...config}
      data={data}
      animation={false}
      label={label}
      statistic={statistic}
      angleField={config.angleField}
      colorField={config.colorField}
    />
  );
};

export default memo(PieChartWidget);
