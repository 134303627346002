import { useCallback, useEffect, useMemo } from 'react';
import Keycloak from 'keycloak-js';
import tokenService from '../utils/tokenService';
import { GlobalConfig } from '../config';
import { initialGlobalConfig } from '../utils/keycloakConfig';

interface IUserKeycloakParams {
  onAuthenticatedCallback?: () => void;
  globalConfig: GlobalConfig;
}

interface IUseKeyCloakResponse {
  doLogin: () => void;
  doLogout: () => void;
  getKcToken: () => void;
  isLoggedIn: () => void;
  updateKcToken: (
    successCallback: () => void,
    failureCallback: (error: any) => void,
  ) => Promise<string | undefined>;
  getUsername: () => string;
}

let kcInstance: Keycloak = new Keycloak();
let _globalConfig: GlobalConfig = initialGlobalConfig;

export const doLogin = kcInstance?.login;

export const doLogout = () => {
  const { protocol, host } = window.location;
  kcInstance.logout({
    redirectUri: `${protocol}//${host}`,
  });
};

export const getKcToken = () => kcInstance.token;
export const getKcRefreshToken = (): string | undefined =>
  kcInstance.refreshToken;

export const updateKcToken = async (
  successCallback?: () => void,
  failureCb?: any,
): Promise<string | undefined> => {
  try {
    const refreshed = await kcInstance.updateToken(-1);

    tokenService.setAccessToken(getKcToken() || '');
    tokenService.setRefreshToken(getKcRefreshToken() || '');
    if (successCallback) {
      successCallback();
    }

    if (refreshed) {
      return getKcToken();
    }
  } catch (e: unknown) {
    if (failureCb) {
      failureCb(e);
    }

    doLogin();
  }
};

const getUsername = (): string => kcInstance.tokenParsed?.preferred_username;

const isLoggedIn = (): boolean => !!kcInstance.token;

const useKeyCloak = (options: IUserKeycloakParams): IUseKeyCloakResponse => {
  const { onAuthenticatedCallback, globalConfig } = options;
  const _kc = useMemo(
    () =>
      new Keycloak({
        url: globalConfig.receivedConfig?.auth.url,
        clientId: globalConfig.receivedConfig?.auth.clientId || '',
        realm: globalConfig.receivedConfig?.auth.realm || '',
      }),
    [globalConfig],
  );
  _globalConfig = globalConfig;

  const initKeycloak = useCallback(() => {
    _kc
      .init({
        onLoad: 'login-required',
        flow: 'standard',
        token: tokenService.getAccessToken() || undefined,
        refreshToken: tokenService.getRefreshToken() || undefined,
        checkLoginIframe: false,
      })
      .then(() => {
        kcInstance = _kc;
        tokenService.setAccessToken(_kc.token || '');
        tokenService.setRefreshToken(_kc.refreshToken || '');
        if (onAuthenticatedCallback) {
          onAuthenticatedCallback();
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, [_kc, onAuthenticatedCallback]);

  useEffect(() => {
    if (onAuthenticatedCallback) {
      initKeycloak();
    }
  }, [onAuthenticatedCallback, initKeycloak]);

  return {
    doLogin,
    doLogout,
    getKcToken,
    isLoggedIn,
    updateKcToken,
    getUsername,
  };
};

export default useKeyCloak;
