import React from 'react';
import _ from 'lodash';
import {
  Form,
  Input,
  Select,
  Button,
  Tabs,
  Row,
  Col,
  Space,
  Popconfirm,
} from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  WidgetDatasourceType,
  WidgetTypes,
} from '../../features/dashboard/types/widget';
import { WidgetGrid } from '../../features/dashboard/types/dashboard';
import MonacoEditor from 'react-monaco-editor';
import styles from './WidgetPropsDrawer.module.less';

interface WidgetPropsFormProps {
  widgetGrid: WidgetGrid;
  onSubmit: (widgetGrid: WidgetGrid) => void;
  onDeleteWidgetGrid: (widgetGridId: string) => void;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const editorProps = {
  width: 800,
  height: 310,
};

const WidgetPropsForm: React.FC<WidgetPropsFormProps> = ({
  widgetGrid,
  onSubmit,
  onDeleteWidgetGrid,
}) => {
  const intl = useIntl();

  const validateMessages = {
    required: intl.formatMessage(
      {
        id: 'widgetForm.requiredField',
        defaultMessage: '{label} - обязательное поле',
      },
      { label: '${label}' },
    ),
  };

  const STATIC = intl
    .formatMessage({
      id: 'widgetForm.Static',
      defaultMessage: 'Статический',
    })
    .toUpperCase();

  const QUERY = intl
    .formatMessage({
      id: 'widgetForm.Query',
      defaultMessage: 'Запрос',
    })
    .toUpperCase();

  const name = intl.formatMessage({
    id: 'widgetForm.Name',
    defaultMessage: 'Название',
  });

  const parameterName = intl.formatMessage({
    id: 'widgetForm.ParameterName',
    defaultMessage: 'Название параметра',
  });

  const type = intl.formatMessage({
    id: 'widgetForm.Type',
    defaultMessage: 'Тип',
  });

  const datasourceType = intl.formatMessage({
    id: 'widgetForm.DatasourceType',
    defaultMessage: 'Тип источника',
  });

  const onFinish = (values: object) => {
    const newWidgetGrid = _.merge({}, widgetGrid, values);

    onSubmit(newWidgetGrid);
  };

  const handleWidgetDelete = () => {
    if (widgetGrid.id) {
      onDeleteWidgetGrid(widgetGrid.id);
    }
  };

  const { TabPane } = Tabs;
  const mainTab = (
    <FormattedMessage
      id="widgetForm.Main"
      defaultMessage="Главоное"
      description="Widget form tab name"
    />
  );

  return (
    <Row>
      <Col span={20}>
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          size="small"
          initialValues={widgetGrid}
        >
          <Tabs tabBarExtraContent={widgetGrid.widget.name}>
            <TabPane tab={mainTab} key="1">
              <Form.Item
                name={['widget', 'name']}
                label={name}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['widget', 'idName']}
                label={parameterName}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name={['widget', 'type']} label={type}>
                <Select>
                  <Select.Option value={WidgetTypes.TEXT}>
                    <FormattedMessage
                      id="widgetForm.Text"
                      defaultMessage="Текст"
                    />
                  </Select.Option>
                  <Select.Option value={WidgetTypes.DATE}>
                    <FormattedMessage
                      id="widgetForm.Date"
                      defaultMessage="Дата"
                    />
                  </Select.Option>
                  <Select.Option value={WidgetTypes.DATETIME}>
                    <FormattedMessage
                      id="widgetForm.DateTime"
                      defaultMessage="Дата и время"
                    />
                  </Select.Option>
                  <Select.Option value={WidgetTypes.TIME}>
                    <FormattedMessage
                      id="widgetForm.Time"
                      defaultMessage="Время"
                    />
                  </Select.Option>
                  <Select.Option value={WidgetTypes.TABLE}>
                    <FormattedMessage
                      id="widgetForm.Table"
                      defaultMessage="Таблица"
                    />
                  </Select.Option>
                  <Select.Option value={WidgetTypes.CHART}>
                    <FormattedMessage
                      id="widgetForm.Chart"
                      defaultMessage="Диаграмма"
                    />
                  </Select.Option>
                  <Select.Option value={WidgetTypes.SELECT}>
                    <FormattedMessage
                      id="widgetForm.Select"
                      defaultMessage="Селект"
                    />
                  </Select.Option>
                  <Select.Option value={WidgetTypes.STATISTIC}>
                    <FormattedMessage
                      id="widgetForm.Statistic"
                      defaultMessage="Статистика"
                    />
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={['widget', 'datasourceType']}
                label={datasourceType}
              >
                <Select>
                  <Select.Option value={WidgetDatasourceType.STATIC}>
                    {STATIC}
                  </Select.Option>
                  <Select.Option value={WidgetDatasourceType.QUERY}>
                    {QUERY}
                  </Select.Option>
                </Select>
              </Form.Item>
            </TabPane>
            <TabPane
              tab={
                <FormattedMessage
                  id="widgetForm.Data"
                  defaultMessage="Данные"
                  description="Widget form tab name"
                />
              }
              key="2"
            >
              <Form.Item
                label={
                  <FormattedMessage
                    id="widgetForm.Query"
                    defaultMessage="Запрос"
                    description="Widget form item label"
                  />
                }
                name={['widget', 'widgetDatasourceRule', 'value']}
                rules={[{ required: true }]}
              >
                <MonacoEditor {...editorProps} language="sql" />
              </Form.Item>
            </TabPane>
            <TabPane
              tab={
                <FormattedMessage
                  id="widgetForm.Styles"
                  defaultMessage="Стили"
                  description="Widget form tab name"
                />
              }
              key="3"
            >
              <Form.Item
                name={['widget', 'component']}
                label={
                  <FormattedMessage
                    id="widgetForm.Config"
                    defaultMessage="Конфигурация"
                    description="Widget form item label"
                  />
                }
              >
                <MonacoEditor {...editorProps} language="json" />
              </Form.Item>
            </TabPane>
          </Tabs>
          <Form.Item className={styles.formButtons}>
            <Space>
              <Button type="primary" htmlType="submit">
                <FormattedMessage
                  id="dashboard.Save"
                  defaultMessage="Сохранить"
                />
              </Button>
              <Popconfirm
                title={intl.formatMessage(
                  {
                    id: 'dashboard.WidgetRemovingAlert',
                    defaultMessage:
                      'Вы действительно хотите удалить виджет {widgetName}?',
                  },
                  {
                    widgetName: widgetGrid.widget.name,
                  },
                )}
                onConfirm={handleWidgetDelete}
              >
                <Button danger={true}>
                  <FormattedMessage
                    id="app.Delete"
                    defaultMessage="Удалить"
                    description="Delete button label"
                  />
                </Button>
              </Popconfirm>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default WidgetPropsForm;
