import { useEffect, useState } from 'react';
import { fetchGlobalConfig } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../store';

const ConfigProvider = (props: { children: JSX.Element }) => {
  const dispatch = useAppDispatch();

  const [config, setConfig] = useState<null | object>(null);
  useEffect(() => {
    dispatch(
      fetchGlobalConfig(configResponse => {
        setConfig(configResponse);
      }),
    );
  }, [dispatch]);

  if (!config) return null;

  return props.children;
};

export default ConfigProvider;
