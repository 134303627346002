import React from 'react';
import Router from '../../Router';
import { useSelector } from 'react-redux';
import { isLoading } from '../../features/user/userSlice';
import Provider from '../../i18n/Provider';
import { getLocale } from '../../i18n/i18nSlice';
import ErrorMessenger from '../ErrorMessenger';
import AppSpin from '../AppSpin';

const App = () => {
  const loading = useSelector(isLoading);
  const locale = useSelector(getLocale);

  if (loading) {
    return <AppSpin />;
  }

  return (
    <Provider locale={locale}>
      <Router />
      <ErrorMessenger />
    </Provider>
  );
};

export default App;
