import React, { CSSProperties, ErrorInfo, ReactNode } from 'react';
import { Alert } from 'antd';

interface ErrorBoundaryProps {
  children: ReactNode;
  errorMessage?: string;
  setErrorMessage?: (message: string) => void;
  style?: CSSProperties;
}

interface ErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
    this.props.setErrorMessage?.(error?.message || '');
  }

  render() {
    const { error } = this.state;
    const { errorMessage } = this.props;

    if (error || errorMessage) {
      const errorText = error?.message || errorMessage;

      return (
        <div title={errorText}>
          <Alert message="Error" description={errorText} type="error" />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
