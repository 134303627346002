import React, { useCallback } from 'react';
import { logout } from '../../features/auth/authSlice';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { doLogout } from '../../hooks/useKeycloak';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '../../store';

const LogoutButton = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const logoutTitle = intl.formatMessage({
    id: 'login.Logout',
    defaultMessage: 'Выйти',
  });

  const handleClick = useCallback(() => {
    doLogout();
    dispatch(logout());
  }, [dispatch]);

  return (
    <Button
      shape="circle"
      onClick={handleClick}
      icon={<LogoutOutlined />}
      title={logoutTitle}
    />
  );
};

export default LogoutButton;
