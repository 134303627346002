import React from 'react';
import { WidgetTypes } from '../../features/dashboard/types/widget';
import {
  CalendarOutlined,
  FieldTimeOutlined,
  FontSizeOutlined,
  LineChartOutlined,
  TableOutlined,
  SelectOutlined,
  FieldBinaryOutlined,
} from '@ant-design/icons';
import { intl } from '../../utils/intl';

export default [
  {
    type: WidgetTypes.TEXT,
    icon: <FontSizeOutlined />,
    title: intl.formatMessage({
      id: 'widgetForm.Text',
      defaultMessage: 'Текст',
    }),
  },
  {
    type: WidgetTypes.DATE,
    icon: <CalendarOutlined />,
    title: intl.formatMessage({
      id: 'widgetForm.Date',
      defaultMessage: 'Дата',
    }),
  },
  {
    type: WidgetTypes.DATETIME,
    icon: <CalendarOutlined />,
    title: intl.formatMessage({
      id: 'widgetForm.DateTime',
      defaultMessage: 'Дата и время',
    }),
  },
  {
    type: WidgetTypes.TIME,
    icon: <FieldTimeOutlined />,
    title: intl.formatMessage({
      id: 'widgetForm.Time',
      defaultMessage: 'Время',
    }),
  },
  {
    type: WidgetTypes.TABLE,
    icon: <TableOutlined />,
    title: intl.formatMessage({
      id: 'widgetForm.Table',
      defaultMessage: 'Таблица',
    }),
  },
  {
    type: WidgetTypes.CHART,
    icon: <LineChartOutlined />,
    title: intl.formatMessage({
      id: 'widgetForm.Chart',
      defaultMessage: 'Диаграмма',
    }),
  },
  {
    type: WidgetTypes.SELECT,
    icon: <SelectOutlined />,
    title: intl.formatMessage({
      id: 'widgetForm.Select',
      defaultMessage: 'Селект',
    }),
  },
  {
    type: WidgetTypes.STATISTIC,
    icon: <FieldBinaryOutlined />,
    title: intl.formatMessage({
      id: 'widgetForm.Statistic',
      defaultMessage: 'Статистика',
    }),
  },
];
