import { WidgetGrid, WidgetPosition } from './dashboard';
import { Modify } from '../../../utils/tsHelpers';

export enum WidgetTypes {
  CHART = 'CHART',
  TABLE = 'TABLE',
  LIST = 'LIST',
  TIME = 'TIME',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  STATISTIC = 'STATISTIC',
}

export enum WidgetDatasourceType {
  /**
   * параметр для виджетов для работы с запросами БД
   */
  QUERY = 'QUERY',

  /**
   * Данные из очереди
   */
  QUEUE = 'QUEUE',

  /**
   * Статичные данные
   */
  STATIC = 'STATIC',

  UNKNOWN = 'UNKNOWN',
}

export interface WidgetDatasourceRule {
  value: string;
}

export interface Widget {
  id?: string;
  name: string;
  idName: string;
  component: string;
  widgetDatasourceRule: WidgetDatasourceRule;
  type: WidgetTypes;
  datasourceType: WidgetDatasourceType;
  parameter: any;
}

interface CreateWidgetOptions {
  type: WidgetTypes;
}

type NewWidgetType = Modify<
  Widget,
  {
    name: null;
    idName: null;
  }
>;

export type NewWidgetGridType = Modify<WidgetGrid, { widget: NewWidgetType }>;

export const createWidget = (options: CreateWidgetOptions): NewWidgetType => {
  return {
    component: '{}',
    idName: null,
    name: null,
    type: options.type,
    widgetDatasourceRule: { value: '' },
    datasourceType: WidgetDatasourceType.STATIC,
    parameter: null,
  };
};

export interface CreateWidgetGridOptions {
  widgetOptions: CreateWidgetOptions;
  position: WidgetPosition;
}

export const createWidgetGrid = (
  options: CreateWidgetGridOptions,
): NewWidgetGridType => {
  return {
    position: options.position,
    widget: createWidget(options.widgetOptions),
  };
};
