import _ from 'lodash';
import { ChartType } from './types/chart';
import { WidgetConfig } from './types/common';
import {
  createTableWidgetColumn,
  TableWidgetColumn,
  TableWidgetData,
} from './types/table';
import React from 'react';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  FireOutlined,
  LikeOutlined,
} from '@ant-design/icons';
import { PlainObject } from '../../types/common';
import { intl } from '../../utils/intl';
/**
 * Default columns setting for TableWidget
 *
 * @param data
 */

const DEFAULT_INTERVAL = 5000;

export const getDefaultConfig = (chartType: ChartType) => {
  switch (chartType) {
    case 'Line':
      return {
        data: [],
        title: {
          visible: true,
          text: 'Chart',
        },
        xField: 'year',
        yField: 'value',
      };
  }
};
export const parseConfig = (config: string): WidgetConfig => {
  try {
    return JSON.parse(config);
  } catch (e) {
    throw Error('Cannot parse JSON');
  }
};

export const getDefaultColumns = (
  data: TableWidgetData[],
): TableWidgetColumn[] => {
  if (_.isEmpty(data)) {
    return [];
  }
  return Object.keys(data[0]).map(item =>
    createTableWidgetColumn(item, item, item),
  );
};

export const getInterval = (
  config: WidgetConfig,
  shouldStop: boolean,
  defaultValue = DEFAULT_INTERVAL,
) => {
  const { interval } = config;

  if (shouldStop) {
    return null;
  }

  if (interval === undefined) {
    return defaultValue;
  }

  return interval;
};

export const statisticWidgetIconMap: PlainObject = {
  arrowUp: <ArrowUpOutlined />,
  arrowDown: <ArrowDownOutlined />,
  clockCircle: <ClockCircleOutlined />,
  like: <LikeOutlined />,
  fire: <FireOutlined />,
  calendar: <CalendarOutlined />,
};

export const untitled = intl.formatMessage({
  id: 'widget.untitled',
  defaultMessage: 'Без названия',
});

export const renderStatistic = (containerWidth: number, text: string) => {
  const R = containerWidth / 2;
  const scale = Math.min(
    Math.sqrt(
      Math.abs(Math.pow(R, 2) / (Math.pow(10 / 2, 2) + Math.pow(10, 2))),
    ),
    1,
  );
  const textStyleStr = `width:${containerWidth}px;`;
  return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
    scale < 1 ? 1 : 'inherit'
  };">${text}</div>`;
};
