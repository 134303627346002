import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAuthorized } from '../../features/auth/authSlice';
import { hasAccess } from '../../utils/rights';
import { selectCurrentUserGrants } from '../../features/user/userSlice';
import { Permissions } from '../../features/settings/types/user';
import { getError } from '../../features/error/errorSlice';
import { accessProhibited } from '../../utils/errors';

interface ProtectedRouteProps extends RouteProps {
  accessRightsList?: Permissions[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  accessRightsList = [],
  ...other
}) => {
  const isAuth = useSelector(isAuthorized);
  const currentUserGrants = useSelector(selectCurrentUserGrants);
  const error = useSelector(getError);

  if (error === accessProhibited) {
    return <Redirect to="/login" />;
  }

  if (isAuth) {
    if (
      accessRightsList.length &&
      !hasAccess(accessRightsList, currentUserGrants)
    ) {
      return <Redirect to="/" />;
    }

    return <Route {...other} />;
  }

  return <Redirect to="/" />;
};

export default ProtectedRoute;
