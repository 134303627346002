import { FormattedMessage } from "react-intl";
import React from "react";

export const returnToAuthBtn = (
  <FormattedMessage
    id="login.returnToAuth"
    defaultMessage="Вернуться к авторизации"
  />
);

export const errorTitleFirstPart = (
  <FormattedMessage
    id="errors.AccessDeniedFirstPart"
    defaultMessage="Доступ запрещён"
  />
);

export const errorTitleSecondPart = (
  <FormattedMessage
    id="errors.AccessDeniedSecondPart"
    defaultMessage="для получения прав обратитесь к администратору"
  />
);

export const promoText = (
  <FormattedMessage
    id="login.loginFormText"
    defaultMessage="Эффективное управление персоналом и снижение затрат на фонд оплаты труда"
  />
);

export const altDescription = "dashboards presentation"
