import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { ItemTypes } from '../../features/dashboard/types/dndItemTypes';
import styles from './WidgetButton.module.less';
import cn from 'classnames';
import {
  createWidgetGrid,
  CreateWidgetGridOptions,
  WidgetTypes,
} from '../../features/dashboard/types/widget';
import { addWidget } from '../../features/dashboard/dashboardSlice';
import { WidgetPosition } from '../../features/dashboard/types/dashboard';
import { useAppDispatch } from '../../store';

interface WidgetButtonProps {
  icon: ReactNode;
  name: WidgetTypes;
  title: string;
}

const defaultWidgetPosition: WidgetPosition = {
  h: 1,
  w: 2,
  x: 1,
  y: 1,
};

const WidgetButton: React.FC<WidgetButtonProps> = ({ icon, name, title }) => {
  const dispatch = useAppDispatch();
  const [{ isDragging }, drag] = useDrag({
    item: { name, type: ItemTypes.BOX },
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const dashboardId = dropResult.id;
        const options: CreateWidgetGridOptions = {
          widgetOptions: { type: name },
          position: defaultWidgetPosition,
        };
        const widgetGrid = createWidgetGrid(options);

        dispatch(addWidget(dashboardId, widgetGrid));
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Button
      ref={drag}
      icon={icon}
      type="text"
      size="large"
      title={title}
      className={cn({ [styles.active]: isDragging })}
    />
  );
};

export default WidgetButton;
