import React from 'react';
import { ReactComponent as LogoImg } from '../../../../components/Logo/logo.svg';
import styles from '../../routes/Login/Login.module.less';

const LoginPageLogo = () => {
  return (
    <div className={styles.logoContainer}>
      <LogoImg className={styles.svg} style={{}} />
      <span className={styles.dataWord}>DATA</span>
      <span className={styles.viewWord}> VIEW</span>
    </div>
  );
};

export default LoginPageLogo;
