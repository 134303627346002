import React, { ReactNode } from 'react';
import { Locale } from './locale';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import enUS from 'antd/es/locale/en_US';
import deDE from 'antd/es/locale/de_DE';
import messages_en from './messages/en_US.json';
import messages_ru from './messages/ru_RU.json';
import messages_de from './messages/de_DE.json';
import moment from 'moment';

interface ProviderProps {
  children: ReactNode;
  locale: Locale;
}

moment.updateLocale('de', {
  longDateFormat: {
    ...require('moment/locale/de').longDateFormat,
    L: 'DD.MM.YYYY',
  },
});

moment.updateLocale('ru', {
  longDateFormat: {
    ...require('moment/locale/ru').longDateFormat,
    L: 'DD.MM.YYYY',
  },
});

const antdLocaleMap = {
  [Locale.RUSSIAN]: ruRU,
  [Locale.ENGLISH]: enUS,
  [Locale.DEUTSCH]: deDE,
};

const Provider: React.FC<ProviderProps> = ({ children, locale }) => {
  const antdLocale = antdLocaleMap[locale];
  moment.locale(locale);

  const messages = {
    [Locale.ENGLISH]: messages_en,
    [Locale.RUSSIAN]: messages_ru,
    [Locale.DEUTSCH]: messages_de,
  };

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
      textComponent={React.Fragment}
    >
      <ConfigProvider locale={antdLocale}>{children}</ConfigProvider>
    </IntlProvider>
  );
};

export default Provider;
