import React from 'react';
import styles from './Logo.module.scss';

interface LogoTitleProps {
  customTitle?: string;
}

const LogoTitle: React.FC<LogoTitleProps> = ({ customTitle }) => {
  if (customTitle) {
    return <span className={styles.customTitle}>{customTitle}</span>;
  }

  return (
    <>
      <span className={styles.second}>Data</span>
      <span className={styles.first}>View</span>
    </>
  );
};

export default LogoTitle;
