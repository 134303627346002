import { LocaleEnum } from '../config';

export const initialGlobalConfig = {
  backend: window.config?.backend,
  receivedConfig: {
    localisation: [LocaleEnum.RU],
    passwordMinLength: 0,
    auth: {
      url: '',
      realm: '',
      clientId: '',
      scopes: '',
    },
  },
};
