import { DashboardListItem } from '../../features/dashboard/types/dashboard';

// Filter dashboard list and return only including searchStr
export const search = (
  dashboardList: DashboardListItem[],
  searchStr: string,
): DashboardListItem[] => {
  return dashboardList.filter(dashboard =>
    dashboard.name.toUpperCase().includes(searchStr.toUpperCase()),
  );
};
