import React from 'react';
import styles from './Logo.module.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoImg } from './logo.svg';
import LogoTitle from './LogoTitle';

interface Props {
  withoutTitle?: boolean;
  customTitle?: string;
}

const Logo: React.FC<Props> = ({ customTitle, withoutTitle = false }) => {
  return (
    <Link to="/" className={styles.container}>
      <div className={styles.img}>
        <LogoImg className={styles.svg} />
      </div>
      {!withoutTitle && <LogoTitle customTitle={customTitle} />}
    </Link>
  );
};

export default Logo;
