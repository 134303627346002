import { Profile } from '../features/settings/types/user';

export const generateUniqueName = (baseName: string) => {
  const timestamp = new Date().getUTCMilliseconds();

  return `${baseName}${timestamp}`;
};

export const cbEmpty = (value: any): any => value;

export const getFullName = (profile: Profile) => {
  const { firstName, lastName, middleName } = profile;

  return `${lastName ? lastName : ''} ${firstName ? firstName : ''} ${
    middleName ? middleName : ''
  }`.trim();
};

export const isEven = (value: number): boolean => {
  return value % 2 === 0;
};

export const sortAlphabetically = (a: string | null, b: string | null) => {
  const stringA = a || '';
  const stringB = b || '';

  if (stringA.toLowerCase() > stringB.toLowerCase()) {
    return 1;
  }
  if (stringA.toLowerCase() < stringB.toLowerCase()) {
    return -1;
  }

  return 0;
};

/**
 * Will return random number between 1 and passed 'length'
 */
export const getRandomNumber = (length: number) => {
  return Math.floor(Math.random() * length) + 1; // + 1 - is without zero
};

export function uuid(): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}
