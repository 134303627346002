import { Permissions } from '../features/settings/types/user';

export const hasAccess = (
  requiredRights: Permissions[],
  userRights: Permissions[],
  every?: boolean,
) => {
  const someRights = requiredRights.some(right => userRights.includes(right));
  const everyRights = requiredRights.every(right => userRights.includes(right));
  return every ? everyRights : someRights;
};
