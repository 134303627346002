export type Token = string;

const ACCESS_TOKEN = 'a_token';
const REFRESH_TOKEN = 'r_token';

export default {
  setAccessToken: (token: Token): void =>
    localStorage.setItem(ACCESS_TOKEN, token),
  setRefreshToken: (token: Token): void => localStorage.setItem(REFRESH_TOKEN, token),
  getAccessToken: (): string | null => localStorage.getItem(ACCESS_TOKEN),
  getRefreshToken: (): string | null => localStorage.getItem(REFRESH_TOKEN),
  clear: (): void => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  },
};
