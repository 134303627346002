import { Dashboard, WidgetGrid } from '../../types/dashboard';
import { Layout } from 'react-grid-layout';
import _ from 'lodash';

/**
 * Getting widget position parameters in a format compatible with react-grid-layout
 *
 * @param dashboard
 */
export const getLayout = (dashboard: Dashboard): Layout[] => {
  return dashboard.widgetGrids.map(grid => {
    if (!grid.id) {
      throw Error('The widget grid needs id');
    }

    return {
      i: grid.id,
      ...grid.position,
    };
  });
};

/**
 * Applying new widget positions to a dashboard
 *
 * @param dashboard
 * @param layout
 */
export const updateDashboardGridPositions = (
  dashboard: Dashboard,
  layout: Layout[],
): Dashboard => {
  const newWidgetGrids: WidgetGrid[] = dashboard.widgetGrids.map(grid => {
    const position = layout.find(l => l.i === grid.id);

    return {
      ...grid,
      position: position ? _.omit(position, 'i') : grid.position,
    };
  });

  return { ...dashboard, widgetGrids: newWidgetGrids };
};

export const isActiveWidgetGrid = (
  widgetGrid: WidgetGrid,
  activeWidgetGrid: WidgetGrid | null,
): boolean => {
  if (!activeWidgetGrid) {
    return false;
  }

  return widgetGrid.id === activeWidgetGrid.id;
};
