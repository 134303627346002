import ColumnHeader from './ColumnHeader';
import React, { ReactNode } from 'react';
import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { green, red } from '@ant-design/colors';
import { intl } from '../../../../utils/intl';
import { PlainObject } from '../../../../types/common';
import {
  Filter,
  FilterItem,
  TableWidgetColumn,
  TableWidgetColumnWithFilters,
} from '../../types/table';

const emptyLabel = intl.formatMessage({
  id: 'Common.empty',
  defaultMessage: 'Пусто',
});

export const addKeys = <
  T extends { id?: string; key?: string; children?: T[] },
>(
  data: T[],
): T[] =>
  data?.map(el => ({
    ...el,
    ...(!el?.key && { key: el?.id }),
    ...(el.children?.length && { children: addKeys(el.children) }),
  }));

export const makeTableFilterFromData = (
  data: PlainObject[],
  key: string,
): Filter => {
  const uniqueValues = Array.from(new Set(data.map(item => item[key])));
  return uniqueValues.map(item => {
    return {
      value: item || '',
      text: item || emptyLabel,
    };
  });
};

export const addFiltersToColumns = (
  filteringColumns: TableWidgetColumn[],
  dataSource: PlainObject[] = [],
  allData: PlainObject[] = [],
): TableWidgetColumnWithFilters[] => {
  return filteringColumns.map(column => {
    if (column && column.children && column.children.length > 0) {
      return {
        ...column,
        children: addFiltersToColumns(column.children, dataSource),
      };
    }

    return {
      ...column,
      filters: makeTableFilterFromData(allData, column.key),
      filterSearch: true,
      onFilter: (value: FilterItem['value'], record: PlainObject) =>
        (record[column.key] || '') === value,
    };
  });
};

const renderBoolean = (value: boolean): ReactNode => {
  return value ? (
    <CheckSquareOutlined style={{ color: green.primary }} />
  ) : (
    <CloseSquareOutlined style={{ color: red.primary }} />
  );
};

const renderCell = <T,>(value: T) => {
  if (typeof value === 'boolean') {
    return renderBoolean(value);
  }

  return value;
};

export const filteredColumns = <T extends { key: string; children?: T[] }>(
  columns: T[],
  filterKeys: string[],
): T[] => {
  return columns.map(el => {
    const filteredChildren = el.children
      ? filteredColumns(el.children || [], filterKeys)
      : null;
    return {
      ...el,
      children: !filterKeys.includes(el.key) ? filteredChildren : [],
    };
  });
};

export const generateColumns = <
  T extends { children?: T[]; title: string; key: string; isPivot?: boolean },
>(
  columns: T[],
  filterKeys: string[],
  setKeys: (key: string) => void,
  isChild: boolean = false,
): Omit<T, 'children'>[] => {
  return columns
    .map(({ children, ...other }) => [
      {
        ...other,
        render: renderCell,
        ...(!other.isPivot && {
          children: generateColumns(
            children || [],
            filterKeys,
            setKeys,
            other.isPivot,
          ),
        }),
        title: (
          <ColumnHeader
            hasChildren={!!children && !!other.isPivot}
            isChild={isChild}
            dataKey={other.key}
            filterKeys={filterKeys}
            setKeys={setKeys}
            name={other.title}
          />
        ),
      },
      ...(other.isPivot
        ? generateColumns(children || [], filterKeys, setKeys, other.isPivot)
        : []),
    ])
    .flat(Infinity) as Omit<T, 'children'>[];
};
