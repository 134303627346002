import React from 'react';
import { Drawer } from 'antd';
import WidgetPropsForm from './WidgetPropsForm';
import { WidgetGrid } from '../../features/dashboard/types/dashboard';

interface WidgetPropsDrawerProps {
  widgetGrid: WidgetGrid;
  onSubmit: (widgetGrid: WidgetGrid) => void;
  onDeleteWidgetGrid: (widgetGridId: string) => void;
  open: boolean;
  onClose: () => void;
}

const WidgetPropsDrawer: React.FC<WidgetPropsDrawerProps> = ({
  widgetGrid,
  onSubmit,
  onDeleteWidgetGrid,
  open,
  onClose,
}) => {
  const handleMainSubmit = (grid: WidgetGrid) => {
    onSubmit(grid);
  };

  return (
    <Drawer
      visible={open}
      placement="bottom"
      onClose={onClose}
      headerStyle={{ borderBottom: '1px solid #f0f0f0' }}
      height={500}
    >
      {open && (
        <WidgetPropsForm
          widgetGrid={widgetGrid}
          onSubmit={handleMainSubmit}
          onDeleteWidgetGrid={onDeleteWidgetGrid}
        />
      )}
    </Drawer>
  );
};

export default WidgetPropsDrawer;
