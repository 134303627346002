export enum LocaleEnum {
  DE = 'DE',
  RU = 'RU',
  EN = 'EN',
}

export type ReceivedConfigType = {
  localisation: LocaleEnum[];
  passwordMinLength: number;
  auth: {
    url: string;
    realm: string;
    clientId: string;
    scopes: string;
  };
};

export type GlobalConfig = {
  backend: string;
  receivedConfig: ReceivedConfigType;
};

declare global {
  interface Window {
    config: GlobalConfig;
  }
}

const DEFAULT_BACKEND = 'https://dash-demo.dc.oswfm.ru/api';

const configuredBackend = window.config?.backend;

export const baseURL = configuredBackend || DEFAULT_BACKEND;
