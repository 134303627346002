import { Localisation } from '../types/common';

export const storageKey: string = 'locale';

export enum Locale {
  ENGLISH = 'en-US',
  RUSSIAN = 'ru-RU',
  DEUTSCH = 'de-DE',
}

export const localisationMap: Localisation = {
  RU: Locale.RUSSIAN,
  EN: Locale.ENGLISH,
  DE: Locale.DEUTSCH,
};
