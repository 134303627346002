import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card } from 'antd';

const PageNotFound = () => {
  return (
    <Card title={<Alert message="404 - Page Not Found" type="error" />}>
      <p>
        <Link to={'/'}>Main page</Link>
      </p>
    </Card>
  );
};

export default PageNotFound;
