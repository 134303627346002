import { FormattedMessage } from 'react-intl';
import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import React, { ReactNode } from 'react';
import { hasAccess } from '../../../utils/rights';
import { Permissions } from '../../../features/settings/types/user';

const {
  DASHBOARD_READ,
  DASHBOARD_EDIT,
  DATA_EDIT,
  GROUP_EDIT,
  GROUP_READ,
  PRIVILEGE_EDIT,
  USER_EDIT,
  USER_READ,
} = Permissions;

export interface MenuItem {
  name: string;
  label: ReactNode;
  icon: ReactNode;
  link: string;
}

const sideBarButtons = [
  {
    name: 'dashboard',
    label: (
      <FormattedMessage
        id="dashboard.MyDashboards"
        defaultMessage="Мои дашборды"
      />
    ),
    icon: <UserOutlined />,
    link: '/dashboard',
    permissions: [DASHBOARD_EDIT, DASHBOARD_READ],
  },
  {
    name: 'settings',
    label: (
      <FormattedMessage id="dashboard.Settings" defaultMessage="Настройки" />
    ),
    icon: <SettingOutlined />,
    link: '/settings',
    permissions: [
      GROUP_EDIT,
      GROUP_READ,
      USER_EDIT,
      USER_READ,
      PRIVILEGE_EDIT,
      DATA_EDIT,
    ],
  },
];

export const filterByRights = (userPermissions: Permissions[]) =>
  sideBarButtons.filter(btn => hasAccess(btn.permissions, userPermissions));
