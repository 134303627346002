import React, { useEffect, useState } from 'react';
import styles from './User.module.scss';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import AddUserDrawer from '../../features/settings/routes/SettingsUsers/AddUser/AddUserDrawer';
import { useSelector } from 'react-redux';
import { fetchUser, selectCurrentUser } from '../../features/user/userSlice';
import { cbEmpty, getFullName } from '../../utils';
import { fetchUserList } from '../../features/settings/settingsSlice';
import {
  formatWithTimezone,
  lastEntranceDateFormat,
  serverDateFormat,
} from '../../utils/dateFormats';
import { useAppDispatch } from '../../store';

const src =
  'https://test.oswfm.ru/wfmserver/api/user-avatar/download/4303f812-5fff-21b4-c8c3-31a7f47f91d4?72d440a9-3b0a-4e9b-9094-b244e7e17114';

const User: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useSelector(selectCurrentUser);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  useEffect(() => {
    if (!currentUser) {
      dispatch(fetchUser());
    }
  }, [currentUser, dispatch]);

  const handleClick = () => {
    setShowDrawer(true);
  };

  const handleSaveSuccess = () => {
    dispatch(fetchUser());
    dispatch(
      fetchUserList({
        size: 0,
        page: 0,
      }),
    );
  };

  const userName =
    currentUser && (getFullName(currentUser?.profile) || currentUser?.roleName);

  const lastEntrance = formatWithTimezone(
    currentUser?.lastEntrance,
    lastEntranceDateFormat,
    serverDateFormat,
  );

  return (
    <div>
      <div className={styles.container} onClick={handleClick}>
        <div>
          <Avatar className={styles.avatar} icon={<UserOutlined />} src={src} />
        </div>
        <div className={styles.textWrap}>
          <span className={styles.text}>{userName}</span>
          {currentUser?.lastEntrance && (
            <span className={styles.lastEntrance}>{lastEntrance}</span>
          )}
        </div>
      </div>

      <AddUserDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        isUserEditing={true}
        openedUser={currentUser}
        setOpenedUser={cbEmpty}
        onSaveSuccess={handleSaveSuccess}
      />
    </div>
  );
};

export default User;
