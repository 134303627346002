import React, { useEffect } from 'react';
import { Button, Drawer, Form } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from '../styles.module.less';
import { addAccountLabel, editAccountLabel, saveLabel } from './helper';
import { User } from '../../../types/user';
import AddUserForm from './AddUserForm';

interface AddUserDrawerProps {
  setShowDrawer: (drawerState: boolean) => void;
  setOpenedUser: (openedUser: User | null) => void;
  showDrawer: boolean;
  isUserEditing: boolean;
  openedUser: User | null;
  onSaveSuccess?: () => void;
}

const AddUserDrawer: React.FC<AddUserDrawerProps> = ({
  setShowDrawer,
  showDrawer,
  isUserEditing = false,
  openedUser,
  setOpenedUser,
  onSaveSuccess,
}) => {
  const [form] = Form.useForm();

  useEffect(() => form.resetFields(), [form, openedUser]);

  const onClose = () => {
    setShowDrawer(false);
    setOpenedUser(null);
    form.resetFields();
  };

  const handleClick = () => form.submit();

  return (
    <>
      <Drawer
        title={
          <span className={styles.drawerTitle}>
            {isUserEditing ? editAccountLabel : addAccountLabel}
          </span>
        }
        closeIcon={<ArrowLeftOutlined />}
        placement="left"
        width={500}
        onClose={onClose}
        open={showDrawer}
        destroyOnClose
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={handleClick} htmlType="submit" type="primary">
              {saveLabel}
            </Button>
          </div>
        }
      >
        <AddUserForm
          form={form}
          openedUser={openedUser}
          onClose={onClose}
          onSaveSuccess={onSaveSuccess}
          isUserEditing={isUserEditing}
        />
      </Drawer>
    </>
  );
};

export default AddUserDrawer;
