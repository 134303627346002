import { SelectValue } from 'antd/es/select';

export const filterFunc = (inputValue: string, option: any): boolean => {
  return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
};

export const getDefaultValue = (
  mode: 'multiple' | undefined,
  parameter: string[],
): SelectValue => {
  if (mode === 'multiple') {
    return parameter;
  }
  return parameter[0];
};
