import React from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './TableWidget.module.less';

interface ColumnHeaderProps {
  name: string;
  filterKeys: string[];
  setKeys: (key: string) => void;
  dataKey: string;
  hasChildren: boolean;
  isChild: boolean;
}

const ColumnHeader = ({
  name,
  filterKeys,
  setKeys,
  dataKey,
  hasChildren,
  isChild,
}: ColumnHeaderProps) => {
  const showDash = !filterKeys.includes(dataKey) && (hasChildren || isChild);
  const handleExpandClick = () => setKeys(dataKey);
  const expandIcon = filterKeys.includes(dataKey) ? (
    <PlusOutlined />
  ) : (
    <MinusOutlined />
  );

  return (
    <div className={styles.columnHeader}>
      {name}
      {hasChildren && (
        <Button
          size="small"
          className={styles.expandButton}
          onClick={handleExpandClick}
          shape="default"
          icon={expandIcon}
        />
      )}
      {showDash && <div className={styles.dash} />}
    </div>
  );
};

export default ColumnHeader;
