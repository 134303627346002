import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store';
import API from '../../utils/API';
import tokenService from '../../utils/tokenService';
import { reset } from '../user/userSlice';
import { GlobalConfig, ReceivedConfigType } from '../../config';
import { initialGlobalConfig } from '../../utils/keycloakConfig';

interface AuthState {
  isAuthorized: boolean;
  loading: boolean; // is authorizing
  errorMessages: string[];
  globalConfig: GlobalConfig;
}

const initialState: AuthState = {
  isAuthorized: Boolean(tokenService.getAccessToken()),
  loading: false,
  errorMessages: [],
  globalConfig: initialGlobalConfig,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authorize: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isAuthorized = action.payload;
    },
    setLoading: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setGlobalConfig: (
      state: AuthState,
      action: PayloadAction<ReceivedConfigType>,
    ) => {
      state.globalConfig = {
        backend: state.globalConfig.backend,
        receivedConfig: action.payload,
      };
    },
    setError: (state: AuthState, action: PayloadAction<string>) => {
      state.errorMessages.push(action.payload);
    },
    resetErrors: state => {
      state.errorMessages = [];
    },
  },
});

export const { authorize, setError, resetErrors, setLoading, setGlobalConfig } =
  authSlice.actions;

export const login =
  (successCb?: (param?: any) => any): AppThunk =>
  async dispatch => {
    dispatch(resetErrors());
    dispatch(setLoading(true));
    try {
      dispatch(authorize(true));
      if (successCb) {
        successCb();
      }
    } catch (e: any) {
      dispatch(reset()); // remove user from state
      dispatch(authorize(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const logout = (): AppThunk => async dispatch => {
  try {
    tokenService.clear();
    dispatch(reset());
    dispatch(authorize(false));
  } catch (e: any) {
    dispatch(setError(e));
  }
};

export const recoveryPassword =
  (_login: string, successCb: () => void): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));
    try {
      const response = await API.get(
        `/public/account/password/recovery/query?login=${_login}`,
      );

      if (
        typeof successCb === 'function' &&
        response.data.status === 'SUCCESS'
      ) {
        successCb();
      }
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const fetchGlobalConfig =
  (successCb?: (configResponse: ReceivedConfigType) => void): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));
    try {
      const { data } = await API.get('/public/config');

      dispatch(setGlobalConfig(data.result));
      if (typeof successCb === 'function' && data.status === 'SUCCESS') {
        successCb(data.result);
      }
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createPassword =
  (
    password: string,
    token: string | null,
    isCreate: boolean,
    successCb: () => void,
  ): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));
    try {
      let response;
      if (isCreate) {
        response = await API.post(`/public/account/password/create`, {
          password,
          token,
        });
      } else {
        response = await API.post(`/public/account/password/recovery`, {
          password,
          token,
        });
      }

      if (
        typeof successCb === 'function' &&
        response.data.status === 'SUCCESS'
      ) {
        successCb();
      }
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(setLoading(false));
    }
  };

// selectors
export const isAuthorized = (state: RootState): boolean =>
  state.auth.isAuthorized;
export const isLoading = (state: RootState): boolean => state.auth.loading;
export const selectGlobalConfig = (state: RootState): GlobalConfig =>
  state.auth.globalConfig;
export const selectErrors = (state: RootState): string[] =>
  state.auth.errorMessages;

export default authSlice.reducer;
