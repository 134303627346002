import React, { ReactNode } from 'react';
import LogoutButton from '../../LogoutButton';
import Logo from '../../Logo';
import styles from './ManagerNavbar.module.scss';
import User from '../../User';
import EditableTitle from './EditableTitle';

interface Props {
  title?: string;
  children: ReactNode;
  onChangeTitle: (value: string) => void;
}

const ManagerNavbar: React.FC<Props> = ({ title, children, onChangeTitle }) => {
  return (
    <div className={styles.container}>
      <Logo withoutTitle={Boolean(title)} />
      {title && <EditableTitle value={title} onChange={onChangeTitle} />}
      <div className={styles.menu}>{children}</div>
      <User />
      <div>
        <LogoutButton />
      </div>
    </div>
  );
};

export default ManagerNavbar;
