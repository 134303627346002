import React, { useCallback, useState } from 'react';
import { DatePicker, Space } from 'antd';
import moment, { Moment } from 'moment';
import { DateWidgetConfig } from '../../types/dateTime';
import { widgetDateFormat } from '../../../../utils/dateFormats';

interface DateWidgetProps {
  config: DateWidgetConfig;
  onChange: (data: string) => void;
  parameter: string;
}

const getDate = (data: string): Moment | null => (data ? moment(data) : null);

const DateWidget: React.FC<DateWidgetProps> = ({
  parameter,
  config,
  onChange,
}) => {
  const [date, setDate] = useState<Moment | null>(getDate(parameter));

  const handleChange = useCallback(
    (value: Moment | null) => {
      const stringValue = value ? value.format(widgetDateFormat) : '';
      setDate(value);
      onChange(`"${stringValue}"`);
    },
    [onChange],
  );

  const { label, padding } = config;

  return (
    <div style={{ display: 'flex', padding: padding || 0 }}>
      <Space>
        {label && <span>{label}</span>}
        <DatePicker
          size="large"
          format={widgetDateFormat}
          value={date}
          onChange={handleChange}
        />
      </Space>
    </div>
  );
};

export default DateWidget;
