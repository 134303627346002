import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import layoutStyles from '../layout.module.scss';
import ManagerNavbar from '../ManagerNavbar';
import ManagerMenu from '../ManagerMenu/ManagerMenu';
import AppSearch from '../../AppSearch';
import { cbEmpty } from '../../../utils';
import BuildVersion from '../BuildVersion';

const { Header, Content, Sider } = Layout;

interface Props {
  children: ReactNode | ReactNode[];
  onChangeTitle?: (value: string) => void;
}

const ManagerMainLayout: React.FC<Props> = ({
  children,
  onChangeTitle = cbEmpty,
}) => {
  return (
    <Layout>
      <Header className={layoutStyles.header}>
        <ManagerNavbar onChangeTitle={onChangeTitle}>
          <div className={layoutStyles.searchWrap}>
            <AppSearch />
          </div>
        </ManagerNavbar>
      </Header>
      <Layout>
        <Sider className={layoutStyles.sider}>
          <ManagerMenu />
          <BuildVersion />
        </Sider>
        <Content className={layoutStyles.content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default ManagerMainLayout;
