import { matchPath } from 'react-router-dom';
import { MenuItem } from './menu';

export const getSelectedKeys = (path: string, items: MenuItem[]): string[] => {
  const result: string[] = [];

  items.forEach(item => {
    if (matchPath(path, item.link)) {
      result.push(item.name);
    }
  });

  return result;
};
