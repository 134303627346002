import React from 'react';
import { Typography } from 'antd';
import { Permissions } from '../../../features/settings/types/user';
import { useSelector } from 'react-redux';
import { selectCurrentUserGrants } from '../../../features/user/userSlice';
import { hasAccess } from '../../../utils/rights';
import styles from './ManagerNavbar.module.scss';

const { Paragraph } = Typography;

interface EditableTitleProps {
  value: string;
  onChange: (value: string) => void;
}

const EditableTitle: React.FC<EditableTitleProps> = ({ value, onChange }) => {
  const userGrants = useSelector(selectCurrentUserGrants);
  const hasAccessToEdit = hasAccess([Permissions.DASHBOARD_EDIT], userGrants);
  return (
    <Paragraph
      className={styles.title}
      title={value}
      ellipsis
      editable={hasAccessToEdit ? { onChange, maxLength: 253 } : false}
    >
      {value}
    </Paragraph>
  );
};

export default EditableTitle;
