import React, { useCallback } from 'react';
import { TimePicker, Space } from 'antd';
import moment, { Moment } from 'moment';
import { TimeWidgetConfig } from '../../types/dateTime';

interface TimeWidgetProps {
  parameter: string;
  config: TimeWidgetConfig;
  onChange: (data: string) => void;
}

const getMomentObj = (data: string, format: string): Moment | undefined => {
  const date = data ? moment(data, format) : undefined;
  if (date && !date.isValid()) {
    throw Error('Invalid date');
  }
  return date;
};

const TimeWidget: React.FC<TimeWidgetProps> = ({
  parameter,
  config,
  onChange,
}) => {
  const handleChange = useCallback(
    (value: Moment | null, stringValue: string) => {
      onChange(`"${stringValue}"`);
    },
    [onChange],
  );

  const { label, padding, format = 'HH:mm' } = config;

  return (
    <div style={{ display: 'flex', padding: padding || 0 }}>
      <Space>
        {label && <span>{label}</span>}
        <TimePicker
          size="large"
          defaultValue={getMomentObj(parameter, format)}
          onChange={handleChange}
          format={format}
        />
      </Space>
    </div>
  );
};

export default TimeWidget;
