export enum ResponseDataStatus {
  SUCCESS = 'SUCCESS',
}

export enum RequestStatusEnum {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface ResponseData<T = any> {
  problem: string | null;
  result: T;
  status: ResponseDataStatus;
}
