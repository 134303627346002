import React, { memo } from 'react';
import styles from './WidgetsPanel.module.scss';
import { Button, Space } from 'antd';
import buttons from './buttons';
import { ReloadOutlined } from '@ant-design/icons';
import Mode from './Mode';
import { DisplayMode } from '../../features/dashboard/types/displayMode';
import WidgetButton from '../WidgetButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectCurrentUserGrants } from '../../features/user/userSlice';
import { Permissions } from '../../features/settings/types/user';
import { Dashboard } from '../../features/dashboard/types/dashboard';
import { hasAccess } from '../../utils/rights';

interface Props {
  onSave: () => void;
  onReload: () => void;
  displayMode: DisplayMode;
  onChangeDisplayMode: (mode: DisplayMode) => void;
  loading: boolean;
  changed: boolean;
  dashboard: Dashboard | null;
}

const WidgetsPanel: React.FC<Props> = ({
  onSave,
  onReload,
  displayMode,
  onChangeDisplayMode,
  loading,
  changed,
  dashboard,
}) => {
  const intl = useIntl();
  const userGrants = useSelector(selectCurrentUserGrants);
  const isEditing = displayMode === DisplayMode.EDIT;

  const hasAccessToEdit = hasAccess([Permissions.DASHBOARD_EDIT], userGrants);

  return (
    <div className={styles.container}>
      {isEditing &&
        buttons.map(button => (
          <WidgetButton
            key={button.type}
            icon={button.icon}
            name={button.type}
            title={button.title}
          />
        ))}
      <div className={styles.splitter} />
      <Space>
        <Button
          shape="circle"
          icon={<ReloadOutlined />}
          title={intl.formatMessage({
            id: 'dashboard.Refresh',
            defaultMessage: 'Обновить',
          })}
          loading={loading}
          onClick={onReload}
        />
        <Mode
          value={displayMode}
          onChange={onChangeDisplayMode}
          hidden={!hasAccessToEdit}
        />
      </Space>
      <div className={styles.splitter} />
      {isEditing && (
        <Button disabled={!changed} onClick={onSave}>
          <FormattedMessage id="dashboard.Save" defaultMessage="Сохранить" />
        </Button>
      )}
    </div>
  );
};

const MemoizedWidgetsPanel = memo(WidgetsPanel);

export default MemoizedWidgetsPanel;
