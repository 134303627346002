import React, { ReactNode } from 'react';
import { List, Typography } from 'antd';

interface ListWidgetProps {
  data: any;
}

const itemRenderer = (item: string): ReactNode => (
  <List.Item>
    <Typography.Text mark={true}>[ITEM]</Typography.Text> {item}
  </List.Item>
);

const ListWidget: React.FC<ListWidgetProps> = ({ data }) => {
  return <List dataSource={data} renderItem={itemRenderer} />;
};

export default ListWidget;
