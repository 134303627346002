import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { Locale, localisationMap, storageKey } from './locale';
import { setError } from '../features/error/errorSlice';
import { LocaleEnum } from '../config';

interface I18nState {
  locale: Locale;
}

const getStoredLocale = (): Locale => {
  const lsValue: string | null = localStorage.getItem(storageKey);
  switch (lsValue) {
    case Locale.RUSSIAN:
      return Locale.RUSSIAN;
    case Locale.ENGLISH:
      return Locale.ENGLISH;
    case Locale.DEUTSCH:
      return Locale.DEUTSCH;
    default:
      return localisationMap[LocaleEnum.RU] as Locale; // get the first locale from list
  }
};

const initialState: I18nState = {
  locale: getStoredLocale(),
};

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    changeLocaleState: (state: I18nState, action: PayloadAction<Locale>) => {
      state.locale = action.payload;
    },
  },
});

export const { changeLocaleState } = i18nSlice.actions;

export const changeLocale =
  (locale: Locale): AppThunk =>
  async dispatch => {
    try {
      dispatch(changeLocaleState(locale));
      localStorage.setItem(storageKey, locale);
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

// selectors
export const getLocale = (state: RootState): Locale => state.i18n.locale;

export default i18nSlice.reducer;
