import { WidgetConfig } from './common';
import { RowSelectionType } from 'antd/es/table/interface';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { PlainObject } from '../../../types/common';

export type TableWidgetData = object;

export type FilterItem = {
  text: string;
  value: string | number | boolean;
};

export interface TableWidgetColumn {
  title: string;
  dataIndex: string;
  key: string;
  children?: TableWidgetColumn[];
}

export interface TableWidgetColumnWithFilters extends TableWidgetColumn {
  filters?: FilterItem[];
  onFilter?: (value: FilterItem['value'], record: PlainObject) => boolean;
}

export interface TableWidgetConfig extends WidgetConfig {
  columns?: TableWidgetColumn[];
  maxRowCount?: number;
  rowSelection?: boolean;
  rowSelectionType?: RowSelectionType;
  keyFieldName?: string;
  bordered?: boolean;
  size?: SizeType;
}

export const createTableWidgetColumn = (
  title: string,
  dataIndex: string,
  key: string,
): TableWidgetColumn => ({
  title,
  dataIndex,
  key,
});

export type Filter = FilterItem[];
