import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getError,
  clearError,
  getErrorMessage as getErrorMessageSelector,
} from '../../features/error/errorSlice';
import { message } from 'antd';
import { useIntl } from 'react-intl';
import { accessProhibited } from '../../utils/errors';
import { useAppDispatch } from '../../store';

const ErrorMessenger: React.FC = () => {
  const dispatch = useAppDispatch();
  const error = useSelector(getError);
  const errorMessage = useSelector(getErrorMessageSelector);
  const intl = useIntl();

  const defaultErrorMessage = intl.formatMessage({
    id: 'error.error',
    defaultMessage: 'Произошла ошибка',
  });

  const getErrorMessage = useCallback(
    (errorKey: string) => {
      switch (errorKey) {
        case 'unauthorized':
          return intl.formatMessage({
            id: 'error.unauthorized',
            defaultMessage: 'Ошибка авторизации',
          });
        default:
          return errorMessage || defaultErrorMessage;
      }
    },
    [defaultErrorMessage, intl, errorMessage],
  );

  useEffect(() => {
    if (error) {
      if (error !== accessProhibited) {
        message.error(getErrorMessage(error));
      }
      dispatch(clearError());
    }
  }, [error, dispatch, getErrorMessage]);

  return <React.Fragment />;
};

export default ErrorMessenger;
